import React, { useEffect, useRef } from "react";
import ReactHtmlParser, { domToReact } from "html-react-parser";
import { graphql, Link } from "gatsby";
import { Title, Body, Subtitle, SmallPrint } from "@components/Typography";
import tw, { styled } from "twin.macro";
import ContactBlock from "@blocks/ContactBlock";
import Layout from "@components/Layout";
import { uiShowBackButton } from "@components/Header";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import Button from "@components/Button";
import Seo from "gatsby-plugin-wpgraphql-seo";

const Container = styled.div(() => [tw`flex flex-1 flex-col bg-pink `]);

const Content = styled.div(() => [
  tw`flex flex-1 flex-col max-w-[900px] self-center text-left py-[50px] px-[25px] tablet:px-[50px]`,
]);

const Intro = tw.div`py-[40px] text-justify`;

const Insights = ({ data: { post } }) => {
  const { title, content, insightIntro } = post;
  const { intro } = insightIntro || { intro: null };

  const options = {
    replace: ({ name, children }) => {
      if (name === "p") {
        return (
          <Body color="midnight" className="text-justify" noWidth>
            {domToReact(children)}
          </Body>
        );
      }
      if (name === "h3") {
        return (
          <Subtitle color="midnight" className="text-left py-[20px]">
            {domToReact(children)}
          </Subtitle>
        );
      }
    },
  };

  return (
    <Layout>
      <Seo post={post} />
      <Container>
        <div className="pt-[150px] flex flex-row max-w-[900px] self-auto mx-[auto] w-full px-[50px] justify-center tablet:justify-start">
          <Button link="/insights" text={"All insights"} flip size="small" />
        </div>
        <Content>
          <Title fixSize color="midnight" className="text-center">
            {title}
          </Title>
          <Intro>
            <Body color="midnight" noWidth className="insight_content">
              {intro}
            </Body>
          </Intro>
          <div className="text-midnight insight_content">
            {ReactHtmlParser(content, options)}
          </div>
        </Content>
        <ContactBlock />
      </Container>
    </Layout>
  );
};

export default Insights;

export const pageQuery = graphql`
  query PostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      content
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      insightIntro {
        intro
      }
    }
  }
`;
